import axios from "axios";

export default {
    async fetchExtraChargeList({ commit }, payload) {
        const response = await axios.post('/api/extra-charge/list', {
            supplier_alias: payload,
        });

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.extra_charges.length) {
                commit('setExtraCharges', response.data.data.result.extra_charges);
            } else {
                commit('setExtraCharges', null);
            }
        }
    },
    async updateExtraCharge({ commit }, payload)
    {
        const response = await axios.post('/api/extra-charge/update', payload);

        console.log(response);
    },
    setExtraChargeData({ commit, getters }, payload)
    {
        let extraCharge = getters.getExtraChargeByIndex(payload.index);

        if (extraCharge) {
            commit('setExtraChargeData', payload);
        }
    },
}