export default {
    getExtraCharges: (state) => {
        return state.extra_charges;
    },
    getExtraChargeByIndex: (state) => (index) => {
        let result = state.extra_charges[index];

        if (!result) {
            return  null;
        }

        return result;
    },
}
