import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    products: null,
    product_attributes: null,
    last_page: 1,
    current_page: 1,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
