export default {
    setProducts(state, payload) {
        state.products = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setAttributes(state, payload) {
        state.product_attributes = payload;
    },
    setCurrentPage(state, payload) {
        state.current_page = payload;
    },
    spliceAttribute(state, payload) {
        state.product_attributes.splice(payload, 1);
    },
    pushAttribute(state, payload) {
        state.product_attributes.push(payload);
    },
}
