export default {
    getProducts: (state) => {
        console.log(state.products);
        return state.products;
    },
    getAttributes: (state) => {
        return state.product_attributes;
    },
    getLastPage: (state) => {
        return state.last_page;
    },
    getCurrentPage: (state) => {
        return state.current_page;
    },
}