import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import categories from './modules/categories';
import extraCharges from './modules/extra_charges';
import products from './modules/products';

export default new Vuex.Store({
    modules: {
        categories,
        extraCharges,
        products,
    }
});
