import axios from "axios";

export default {
    async fetchProducts({ commit }, payload) {
        const response = await axios.post('/api/products/list', {
            filter_data: payload,
        });

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.products) {
                if (response.data.data.result.products.data) {
                    if (response.data.data.result.products.data.length) {
                        commit('setProducts', response.data.data.result.products.data);
                        commit('setLastPage', response.data.data.result.products.last_page);
                        commit('setCurrentPage', response.data.data.result.products.current_page);
                    }
                }
            }
        }
    },
    async fetchProduct({ commit }, payload) {
        let result = null;

        const response = await axios.post('/api/products/get', {
            product_id: payload,
        });

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.product) {
                if (response.data.data.result.product.id) {
                    result = response.data.data.result;
                }
            }

            console.log(response.data.data.result.product.attributes);

            if (response.data.data.result.product.attributes) {
                commit('setAttributes', response.data.data.result.product.attributes);
            }
        }

        return result;
    },
    async save({ commit }, payload)
    {
        let result = null;
        let url = '/api/products/store';

        if (payload.id) {
            url = '/api/products/update'
        }

        const response = await axios.post(url, payload);

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.success === true) {
                if (response.data.data.result.product) {
                    result = response.data.data.result.product;
                }
            }
        }

        return result;
    },
    async fetchCarList({ commit }, payload)
    {
        let result = null;

        const response = await axios.post('/api/car-model/get-list-by-model', {query: payload});

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.success === true) {
                if (response.data.data.result.cars) {
                    result = response.data.data.result.cars;
                }
            }
        }

        return result;
    },
    async addCarToProduct({ commit }, payload)
    {
        let result = null;

        const response = await axios.post('/api/products/add-car-to-product', payload);

        console.log(response);
    },
    async deleteProductCar({ commit }, payload)
    {
        let result = null;

        const response = await axios.post('/api/products/delete-car-to-product', {id: payload});

        console.log(response);
    },
    async saveProductAttribute({ commit}, payload)
    {
        return await axios.post('/api/product/attribute/store', payload);
    },
    async loadPhotos({ commit }, payload)
    {
        const response = await axios.post('/api/product/photos/load', payload);

        console.log(response);
    },
    async loadMainPhoto({ commit }, payload)
    {
        return await axios.post('/api/product/photos/loadMainPhoto', payload);
    },
    async deleteProductAttribute({ commit }, payload)
    {
        return await axios.post('/api/product/attributes/delete', payload);
    },
    spliceAttribute({ commit }, payload)
    {
        commit('spliceAttribute', payload);
    },
    pushAttribute({ commit }, payload)
    {
        commit('pushAttribute', payload);
    },
}
